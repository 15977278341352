//Accueil.js
import React from "react";
import { useState } from "react";
import { Container, Card, Row, Col, Button, Stack } from "react-bootstrap";
import Annuaire from "./Annuaire.js";
import { Link } from "react-router-dom";
import FichierUpload from "../services/FichierUpload.js";

function Accueil() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={5} xs={12}>
          <h1
            style={{
              fontSize: "2.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            Retrace
          </h1>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={3}>
          <Card>
            {/* <Card.Header>Annuaire</Card.Header> */}
            <Card.Body>
              <Button as={Link} to={"/annuaire"}>
                Consulter les filieres
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Accueil;
